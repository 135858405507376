<template>
  <main>
    <CForm>
      <CCard>
        <CCardBody>
          <div class="mb-4">
            <h5 class="text-info">
              設定付款系統
            </h5>
            <div>
              付款系統包含第三方金流及銀行，若您需要使用信用卡或虛擬ATM等自動收款的方式，請設定付款系統或供應商；<br>
              若您找不到您所使用的第三方金流或銀行，可<router-link class="text-info" to="/feedback">與我們聯絡</router-link>或加入<a class="text-success" href="https://page.line.me/umeans">LINE官方帳號</a>協助服務或洽談串接事宜。
            </div>
          </div>
          <CRow>
            <template v-for="(features, system) in PaymentSystemFeatures">
              <CCol v-if="system !== 'CUSTOM'" :key="system" lg="3" md="4" sm="6" style="cursor: pointer" @click="OpenPaymentMerchantModal(system)">
                <CWidgetBrand color="white" class="position-relative" addHeaderClasses="border-0">
                  <div class="position-absolute" style="top: 10px;right: 10px">
                    <CBadge v-for="feature in features" :key="feature" color="info" class="mr-1">
                      {{ feature }}
                    </CBadge>
                    <CBadge :color="MerchantData[system.toLowerCase()] && MerchantData[system.toLowerCase()].Enable && 'success' || 'danger'">
                      {{ MerchantData[system.toLowerCase()] && MerchantData[system.toLowerCase()].Enable && '已啟用' || '未啟用' }}
                    </CBadge>
                  </div>
                  <div class="p-4 text-center">
                    <img :src="`https://cdn.marketingless.com/public/vendor/payment/${system}.png`" class="w-auto" style="height: 75px" alt="">
                  </div>
                  <template #body>
                   <div class="px-3 pb-3 text-center font-weight-bold">
                     {{ $t('Payment.PaymentSystem.' + system) }}
                   </div>
                  </template>
                </CWidgetBrand>
              </CCol>
            </template>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardBody class="d-flex justify-content-between">
          <div>
            <h5 class="text-info">
              {{ $t('Navigation.System/Payment') }}
            </h5>
            <div>
              需要設定付款系統或供應商後才能設定付款方式與條件，或可直接使用「自訂付款方式/物流代收」自行收款。
            </div>
          </div>
          <div>
            <CButton color="success" type="button" @click="OpenModal()">
              <CIcon name="cil-plus" class="mr-1" /> {{ $t('Global.Add') + $t('Payment.Method') }}
            </CButton>
          </div>
        </CCardBody>
        <CCardBody class="p-0">
          <CDataTable
              id="PaymentList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              responsive
              hover
          >
            <template #No="{index}">
              <td class="text-center">
                {{ index + 1}}
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === true ? 'success' : 'danger')">
                  {{ $t('Payment.StatusType.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #System="{item}">
              <td>
                {{ $t('Payment.PaymentSystem.' + item.System) }}
              </td>
            </template>
            <template #Method="{item}">
              <td>
                {{ $t('Payment.PaymentMethods.' + item.Method) }}
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <CButton color="info" size="sm" class="mr-1" v-c-tooltip="$t('Global.Edit')" @click="OpenModal(item.ID)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size" />
                </CButton>
                <CButton color="danger" size="sm" class="mr-1" v-c-tooltip="$t('Global.Remove')" @click="Delete(item.ID)">
                  <CIcon name="cil-trash" class="c-icon-custom-size"/>
                </CButton>
                <CButton color="secondary" size="sm" class="mr-1" v-c-tooltip="$t('Global.Duplicate')" @click="Duplicate(item.ID)">
                  <CIcon name="cil-copy" class="c-icon-custom-size mr-1" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CForm>
    <CModal id="PaymentDataModal" class="ActionModel" size="lg" :show.sync="PaymentDataModal" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.' + (!ChooseIndex ? 'Add' : 'Edit')) }}{{ $t('Payment.Method') }}</h5>
          <CButtonClose @click="PaymentDataModal = false"/>
        </header>
      </template>
      <CTabs fade addTabsWrapperClasses="tab-info">
        <CTab :title="$t('Payment.BasicSetting')" active>
          <CInput horizontal v-model="PaymentData.Name" :label="$t('Payment.Name')"/>
          <CSelect horizontal :label="$t('Payment.System')" :options="PaymentSystem" v-model="PaymentData.System" :value.sync="PaymentData.System" :placeholder="$t('Global.PleaseSelect')"/>
          <CSelect horizontal :label="$t('Payment.Method')" :options="PaymentMethods" v-model="PaymentData.Method" :value.sync="PaymentData.Method" :placeholder="$t('Global.PleaseSelect')"/>
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              {{ $t('Payment.Status') }}
              <i class="fas fa-info-circle text-info ml-1" v-c-tooltip="{content: '切換啟用狀態不影響銷售頁的付款方式的啟用與否，若銷售頁要停用這項付款方式，請至銷售頁將此付款方式移除。', placement: 'right'}" />
            </CCol>
            <CCol sm="9">
              <CSwitch color="success" :checked.sync="PaymentData.Status"/>
            </CCol>
          </CRow>
          <CInput :prepend="$store.state.currency + '$'" horizontal v-model="PaymentData.Fee" :label="$t('Payment.Fee')" type="number" />
          <CInput horizontal v-model="PaymentData.MinimumAmountThreshold" :prepend="$store.state.currency + '$'" :label="$t('Payment.MinimumAmountThreshold')" :description="$t('Payment.MinimumAmountThresholdDescription')" type="number" />
          <CInput horizontal v-model="PaymentData.MaximumAmountThreshold" :prepend="$store.state.currency + '$'" :label="$t('Payment.MaximumAmountThreshold')" :description="$t('Payment.MaximumAmountThresholdDescription')" type="number" />
          <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
              {{ $t('Payment.Description') }}
            </CCol>
            <CCol sm="9">
              <Editor api-key="4q91812x421qc6po6evarups2m6zj0hj92uulv0nvikgl784" :init="TinyMCE" v-model="PaymentData.Description" />
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('Payment.AllowLogistics')">
          <div class="form-group">
            <label class="d-block">{{ $t('Payment.AllowSpecifyLogisticsEnable') }}<i v-c-tooltip="{content: $t('Payment.AllowSpecifyLogisticsEnableDescription'),placement: 'right'}" class="fas fa-question-circle text-info ml-1" /></label>
            <CSwitch color="success" :checked.sync="PaymentData.AllowSpecifyLogisticsEnable" />
          </div>
          <template v-if="PaymentData.AllowSpecifyLogisticsEnable">
            <h5 class="mb-3 d-flex justify-content-between align-items-center">
              {{ $t('Product/SinglePage.ShipmentWay') }}
              <CButton color="info" type="button" size="sm" @click="AddAllowSpecifyLogistics()">
                {{ $t('Global.Add') + $t('Product/SinglePage.ShipmentWay') }}
              </CButton>
            </h5>
            <CDataTable
              id="LogisticsList"
              :loading="Loading"
              :items="PaymentData.AllowLogisticsList"
              :fields="LogisticsField"
              responsive
              hover
            >
              <template #No="{index}">
                <td class="text-center">
                  {{ index + 1}}
                </td>
              </template>
              <template #Method="{item, index}">
                <td>
                  <CSelect :options="LogisticsListOptions" size="sm" v-model="PaymentData.AllowLogisticsList[index]" :value.sync="PaymentData.AllowLogisticsList[index]" :placeholder="$t('Global.PleaseSelect')" />
                </td>
              </template>
              <template #Action="{index}">
                <td>
                  <CButton color="danger" type="button" size="sm" @click="PaymentData.AllowLogisticsList.splice(index, 1)">
                    <CIcon name="cil-trash" class="c-icon-custom-size" />
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </template>
        </CTab>
        <CTab :title="$t('Payment.AdvanceSetting')">
          <CInput horizontal description="付款完成後(不論成功或失敗)跳轉回網站的連結，若不填寫則會使用系統預設值。" v-model="PaymentData.ClientRedirectURL" label="ClientRedirectURL"/>
          <CInput horizontal description="顧客從付款頁面返回商店的連結，若不填寫則會使用系統預設值。" v-model="PaymentData.ClientBackURL" label="ClientBackURL"/>
        </CTab>
      </CTabs>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true) || (Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="PaymentDataModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateSubmit" @click="Save()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="PaymentMerchantDataModal" class="ActionModel" size="lg" :show.sync="PaymentMerchantDataModal" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">設定{{ $t('Payment.PaymentSystem.' + CurrentPaymentMerchantSystem.toUpperCase()) }}商戶</h5>
          <CButtonClose @click="PaymentMerchantDataModal = false"/>
        </header>
      </template>
      <div class="p-3 border">
        <component :is="Component" :MerchantData.sync="MerchantData" />
      </div>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true) || (Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="PaymentMerchantDataModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateSubmit" @click="SetPayCenterMerchant(CurrentPaymentMerchantSystem)" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "付款方式"
  }
}
</route>

<script>
import Editor from '@tinymce/tinymce-vue'
import TinyMCESetting from '@/plugins/tinymce'

export default {
  name: 'PaymentList',
  layout: 'manage',
  components: {
    Editor
  },
  data () {
    return {
      TinyMCE: TinyMCESetting,
      Component: null,
      Model: false,
      List: [],
      ListObject: {},
      PaymentData: {
        ID: '',
        ServiceID: '',
        Name: '',
        Description: '',
        Image: '',
        Method: '',
        System: '',
        Fee: '0',
        MinimumAmountThreshold: '0',
        Status: false,
        AllowSpecifyLogisticsEnable: false,
        AllowLogisticsList: []
      },
      SystemAllowMethods: {
        ECPAY: [
          'CREDIT', 'CREDIT_PERIOD', 'CREDIT_DIVIDE', 'WEBATM', 'CVS', 'BARCODE', 'ATM'
        ],
        NEWEBPAY: [
          'CREDIT', 'CREDIT_PERIOD', 'CREDIT_DIVIDE', 'WEBATM', 'CVS', 'BARCODE', 'ATM'
        ],
        TAPPAY: [
          'CREDIT', 'CREDIT_PERIOD', 'CREDIT_DIVIDE', 'LINEPAY', 'APPLEPAY', 'JKOPAY'
        ],
        UNIPAY: [
          'CREDIT', 'CREDIT_PERIOD', 'CREDIT_DIVIDE', 'LINEPAY', 'APPLEPAY', 'ATM', 'CVS'
        ],
        TSPGPAY: [
          'CREDIT', 'CREDIT_DIVIDE'
        ],
        FOCASPAY: [
          'CREDIT'
        ],
        VI_MOMOPAY: [
          'CREDIT', 'WEBATM'
        ],
        CUSTOM: [
          'CREDIT', 'CREDIT_PERIOD', 'CREDIT_DIVIDE', 'WEBATM', 'CVS', 'BARCODE', 'ATM', 'BANK', 'CASH_DELIVERY', 'CREDIT_STORE', 'CASH_ONLY'
        ]
      },
      PaymentSystemFeatures: {
        TAPPAY: [
          '免跳轉', '手續費優惠'
        ],
        UNIPAY: [
          '即將推出'
        ],
        ECPAY: [],
        NEWEBPAY: [],
        LINEPAY: [],
        VI_MOMOPAY: [],
        TSPGPAY: [
          '簽約客戶'
        ],
        FOCASPAY: [
          '四大行庫可用'
        ],
        CTBCPAY: [
          '簽約客戶'
        ],
        CATHAYPAY: [
          '簽約客戶'
        ],
        SINOPACPAY: [
          '簽約客戶'
        ],
        ESUNPAY: [
          '簽約客戶'
        ]
      },
      LogisticsList: [],
      LogisticsListOptions: [],
      MerchantData: {
        'ecpay': {
          Taxonomy: 'Payment'
        },
        'newebpay': {
          Taxonomy: 'Payment'
        },
        'tspgpay': {
          Taxonomy: 'Payment'
        },
        'focaspay': {
          Taxonomy: 'Payment'
        },
        'vi_momopay': {
          Taxonomy: 'Payment'
        },
        'unipay': {
          Taxonomy: 'Payment'
        },
        'tappay': {
          Taxonomy: 'Payment'
        }
      },
      ChooseIndex: null,
      PaymentDataModal: false,
      PaymentMerchantDataModal: false,
      CurrentPaymentMerchantSystem: false,
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'No', label: 'No' },
        { key: 'Name', label: this.$t('Payment.Name') },
        { key: 'System', label: this.$t('Payment.System') },
        { key: 'Method', label: this.$t('Payment.Method') },
        { key: 'Status', label: this.$t('Payment.Status') },
        { key: 'Action', label: '' }
      ]
    },
    PaymentSystemSetting () {
      return this.PaymentSystem.map(payment => payment.value)
    },
    PaymentMethods () {
      if (typeof this.PaymentData.System === 'string' && this.PaymentData.System !== '') {
        return Object.keys(this.$t('Payment.PaymentMethods')).filter((methods) => {
          return this.SystemAllowMethods[this.PaymentData.System.toUpperCase()].includes(methods)
        }).map(type => {
          return {
            value: type,
            label: this.$t('Payment.PaymentMethods')[type]
          }
        })
      }
      return []
    },
    PaymentSystem () {
      return Object.keys(this.SystemAllowMethods).map(type => {
        return {
          value: type,
          label: this.$t('Payment.PaymentSystem')[type]
        }
      })
    },
    LogisticsField () {
      return [
        { key: 'No', label: 'No' },
        { key: 'Method', label: this.$t('Logistics.Method') },
        { key: 'Action', label: '' }
      ]
    },
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      this.Loading = true
      return Promise.all([
        this.GetPaymentList(),
        this.GetShipmentList(),
        this.GetMerchantList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      }).finally(() => {
        this.Loading = false
      })
    },
    Add () {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/payment/add',
        method: 'post',
        data: {
          PaymentData: {
            ...this.PaymentData,
            System: this.PaymentData.System.toUpperCase()
          }
        }
      }).then(() => {
        this.Loading = false
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit (ID) {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/payment/set',
        method: 'post',
        data: {
          ID,
          PaymentData: this.PaymentData
        }
      }).then(() => {
        this.Loading = false
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Save () {
      let Action
      this.Loading = true
      if (!this.ChooseIndex) {
        Action = this.Add()
      } else {
        Action = this.Edit(this.ChooseIndex)
      }
      return Action.then(() => {
        this.PaymentDataModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/' + (this.ChooseIndex ? 'Update' : 'Add') + 'PaymentSuccess'),
          type: 'success'
        })
        this.Init()
      }).catch((error) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    },
    Delete(ID) {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/payment/delete',
        method: 'post',
        data: {
          ID
        }
      }).then(() => {
        this.Init()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    OpenModal(ID = null) {
      this.ChooseIndex = ID
      this.PaymentDataModal = true
      if (ID !== null) {
        this.PaymentData = this.ListObject[ID]
      } else {
        this.PaymentData = {
          ID: '',
          ServiceID: '',
          Name: '',
          Description: '',
          Image: '',
          Method: '',
          System: '',
          Fee: 0,
          MinimumAmountThreshold: 0,
          Status: false
        }
      }
    },
    Duplicate(ID) {
      this.PaymentData = this.ListObject[ID]
      this.PaymentData.Name += '_copy'
      return this.Add().then(() => {
        this.PaymentDataModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/CopyPaymentSuccess'),
          type: 'success'
        })
        this.GetList()
      }).catch((error) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + error.msg,
          type: (error.type ? error.type : 'error')
        })
      })
    },
    GetPaymentList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/payment/list',
        method: 'get'
      }).then(({data}) => {
        this.List = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase(),
            AllowLogisticsList: data[id].AllowLogisticsList ? data[id].AllowLogisticsList : []
          }
        })
        this.ListObject = data
      })
    },
    GetShipmentList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/list',
        method: 'get'
      }).then(({data}) => {
        this.LogisticsList = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.LogisticsListOptions = this.LogisticsList.map((item) => {
          return {
            label: `${item.Name} (${this.$t('Order.LogisticsType')}: ${this.$t('Logistics.LogisticsMethods.' + item.Method)}，${this.$t('Order.LogisticsSystem')}: ${this.$t('Logistics.LogisticsSystem.' + item.System)}，${this.$t('Logistics.Fee')}: ${item.Fee}，${this.$t('Logistics.ServiceLocation')}: ${this.$t('Logistics.ServiceLocationOptions.' + item.ServiceLocation)})`,
            value: item.ID
          }
        })
      })
    },
    GetMerchantList() {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/MerchantData',
        method: 'post'
      }).then(({data}) => {
        this.MerchantData = {
          ...this.MerchantData,
          ...data
        }
        return true
      })
    },
    AddAllowSpecifyLogistics() {
      if (!this.PaymentData.AllowLogisticsList) {
        this.$set(this.PaymentData, 'AllowLogisticsList', [])
      }
      this.PaymentData.AllowLogisticsList.push('')
    },
    OpenPaymentMerchantModal(system) {
      this.Component = null
      this.PaymentMerchantDataModal = true
      this.CurrentPaymentMerchantSystem = system.toLowerCase()
      this.Loading = true
      setTimeout(() => {
        this.Component = require('@/components/payment/' + system).default
        this.Loading = false
      }, 500)
    },
    SetPayCenterMerchant(system) {
      if (!Object.keys(this.SystemAllowMethods).includes(system.toUpperCase())) {
        this.PaymentMerchantDataModal = false
        return Promise.resolve()
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/merchant/pay/set',
        method: 'post',
        data: {
          ...this.MerchantData[system],
          System: system
        }
      }).then(() => {
        this.Loading = false
        this.PaymentMerchantDataModal = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.System/ModifySuccess'),
          type: 'success'
        })
        this.Init()
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.System/ModifyFail') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    }
  }
}
</script>

<style>
#PaymentList thead th:first-child {
  text-align: center;
}
#PaymentList tbody td:first-child {
  vertical-align: middle;
}
</style>
