<template>
  <CAlert color="secondary" :fade="false">
    請使用 TapPay 或統一金流 PAYUNi 接入此支付方式。<br>
    透過 TapPay 申請 LINE Pay 線上收款商戶享獨家優惠！
  </CAlert>
</template>

<script>
export default {
  name: "ESANPAY"
}
</script>

<style scoped>

</style>
