<template>
  <div>
    <div class="form-group">
      <label for="unipayEnable" class="d-block">
        啟用統一金流 PAYUNi
        <CBadge color="info" class="ml-1" v-c-tooltip="{content: $t('Global.PreviewDescription'), placement: 'bottom'}">{{ $t('Global.Preview') }}</CBadge>
      </label>
      <CSwitch id="unipayEnable" size="sm" color="success" :checked.sync="MerchantData['unipay'].Enable" />
    </div>
    <template v-if="MerchantData['unipay'].Enable">
      <CInput id="unipayPaymentMerchantID" v-model="MerchantData['unipay'].MerchantID" size="sm" type="text">
        <template #label>
          <label for="unipayPaymentMerchantID" class="d-block">{{ $t('MerchantData.MerchantID') }}</label>
        </template>
      </CInput>
      <CInput id="unipayPaymentHashKey" v-model="MerchantData['unipay'].HashKey" size="sm" type="text">
        <template #label>
          <label for="unipayPaymentHashKey" class="d-block">{{ $t('MerchantData.HashKey') }}</label>
        </template>
      </CInput>
      <CInput id="unipayPaymentHashIV" v-model="MerchantData['unipay'].HashIV" size="sm" type="text">
        <template #label>
          <label for="unipayPaymentHashIV" class="d-block">{{ $t('MerchantData.HashIV') }}</label>
        </template>
      </CInput>
      <div class="form-group">
        <label for="unipayTestMode" class="d-block">{{ $t('MerchantData.TestMode') }}</label>
        <CSwitch id="unipayTestMode" size="sm" color="success" :checked.sync="MerchantData['unipay'].TestMode" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "UNIPAY",
  props: {
    MerchantData: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped>

</style>
